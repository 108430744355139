import * as React from "react";
import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import styled from "styled-components";
import { getTaskAssessments, getTaskDetails } from "../../services/task.service";
import {useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from '../../components/Buttons/CreateButton.component';
import { convertDate } from "../../utils/DateConverter";
import { workflowItemStatusMapper } from "../../features/boarding/workflow/boarding-workflow.service";

import AttachedFiles from "../../components/AttachedFiles/attachedFiles.component";

const ContentBox = styled.div`     
    width: 100%;
    height:fit-content;   
    display: flex;
    flex-direction: column;        
    background: #fff;    
    padding: 15px 10px;
    border: 1px solid rgba(0,0,0,.08);
`;

const ContentHeader = styled.div`
    color: #333; 
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #e5e5e5;
    font-size: 24px;
`;

const ContentBody = styled.div`    
    padding: 30px 15px;
    display: flex;     
    flex-direction: column;
    gap: 32px;
`;

const Content = styled.div`    
    display: flex;     
    flex-direction: column;
    gap: 12px;
`;

const ContentLabel = styled.label`
    font-size: 18px;
`;

const ContentDescription = styled.div`    
    font-size: 14px;
`;

const BoardingStapPage: React.FunctionComponent = () => {
  const navigate = useNavigate();

    const [taskDetails, setTaskDetails] = useState<any>(() => null);
    const [assessments, setAssessments] = useState<any>(() => null);   
    
    let { id } = useParams();    
    
    React.useEffect(() => {      
        async function getAssessments() {            
            var assessments = await getTaskAssessments(String(id));                
            setAssessments(assessments);            
        }
        getAssessments();
        
    },[id]);

    React.useEffect(() => {      
        async function getTask() {            
            const response = await getTaskDetails(String(id));                
            setTaskDetails(response);            
        }
        getTask();        
    },[id])

  return (
    <>
      <DashboardLayout>
        {(taskDetails)? (
        <ContentBox>
            <ContentHeader>Details</ContentHeader>
            <ContentBody>
                <Content>
                    <ContentLabel>Summary</ContentLabel>
                    <ContentDescription>{(taskDetails.description)? taskDetails.description : taskDetails.title }</ContentDescription>         
                </Content>
                <Content>
                    <ContentLabel>Due date</ContentLabel>
                    <ContentDescription>{ convertDate(taskDetails.dueDate) }</ContentDescription>         
                </Content>
                <Content>
                    <ContentLabel>Status</ContentLabel>
                    <ContentDescription>{ workflowItemStatusMapper(taskDetails.taskStatus) }</ContentDescription>         
                </Content>
                
                {(taskDetails.action.type === 'DOCUMENTATION_TRAINING' && taskDetails.files) ? 
                <Content>                   
                    <ContentLabel>Learning materials</ContentLabel>
                    <AttachedFiles files={taskDetails.files} />                  
                </Content> :  null }
                <Content>                                  
                    {(assessments && taskDetails.taskStatus !== 'COMPLETED') ? 
                    <>
                    <ContentLabel>Quiz</ContentLabel>  
                    <Button color="#5156BE" textColor="#fff" onClick={() => {navigate(`/quiz/${id}`)}} text='Start quiz' /> 
                    </>
                    :  null }
                    
                </Content>               
            </ContentBody>
        </ContentBox>) : (<div>Task details not loaded yet</div>)
        }       
      </DashboardLayout>      
    </>
  );
};

export default BoardingStapPage;
