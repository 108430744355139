import * as React from 'react';
import './styles/index.style.css';

interface Props {
  children: React.ReactNode;
}

const IndexLayout: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <>
      <div className="wrapper">
        <div className="leftSideWrapper">
          <div className="sideWrapper">
            <div className="logo">Rocket Desert</div>
            <div className="description">
            Maximizing Business Potential through Superior Onboarding Experiences
            </div>
          </div>
        </div>
        <div className="rightSide">
          <div className="loginWrapper">{props.children}</div>
        </div>
        <div className="copyright">
          &copy; 2024 Rocket Desert. All rights reserved. <span> </span>
          <a className="legalinks" href="https://rocketdesert.com/terms-of-service">Terms & Conditions</a> and <span> </span>
          <a className="legalinks" href="https://rocketdesert.com/privacy-policy">Privacy Policy</a>
        </div>
      </div>
    </>
  );
};

export default IndexLayout;