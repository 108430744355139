import { WorkflowItemStatuses } from "./enums/WorkflowItemStatuses.enum";

export const workflowItemBadgeColorMapper = (status:WorkflowItemStatuses): string => {
    switch(status) {
        case WorkflowItemStatuses.Todo:
            return 'badge-undone';
        case WorkflowItemStatuses.Inprogress:
            return 'badge-undone';
        case WorkflowItemStatuses.Completed:
            return 'badge-done';   
        default:
          return 'badge-undone';
      }        
};

export const workflowItemStatusMapper = (status:WorkflowItemStatuses): string => {
    switch(status) {
        case WorkflowItemStatuses.Todo:
            return 'To Do';
        case WorkflowItemStatuses.Inprogress:
            return 'In Progress';
        case WorkflowItemStatuses.Completed:
            return 'Done';   
        default:
          return 'To Do';
      }        
};

export const workflowItemHedaerStyleMapper = (status:WorkflowItemStatuses): string => {
    switch(status) {
        case WorkflowItemStatuses.Todo:
            return 'workflow-item-header';
        case WorkflowItemStatuses.Inprogress:
            return 'workflow-item-header';
        case WorkflowItemStatuses.Completed:
            return 'workflow-item-header workflow-item-header-complete';   
        default:
          return 'workflow-item-header';
      }        
};