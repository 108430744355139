import * as React from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { verify } from "../../services/boarding.service";
import InputTextComponent from "../../components/input/InputText.component";
import LoginButtonComponent from "../../components/Buttons/LoginButton.component";
import { joinCompany } from "../../services/boarding.service";
import IndexLayout from "../../layouts/index/IndexLayout";
import {
  InfoMessageComponent,
  MessageTypes,
} from "../../components/InfoMessage/infoMessage.component";
import PasswordStrengthComponent from "../../components/PasswordStrength/PasswordStrength.components";
import { PasswordStrengthStatuses } from "../../components/PasswordStrength/PasswordStrength.components";

const ButtonWrapper = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const WelcomeWrapper = styled.div`
  font-size: 15px;
  text-align: left;
`;

const PasswordStrengthWrapper = styled.div`
  text-align: left;
`;

const SetPasswordWrapper = styled.div`
    width: 400px;
    margin:auto;
`;

const VerifyPage: React.FunctionComponent = () => {
  let { token } = useParams();
  const accessToken: any = token || null;
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const [user, setUser] = React.useState<any>();
  const [showError, setShowError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [passwordStrengthStatus, setPasswordStrengthStatus] =
    React.useState<PasswordStrengthStatuses>();

  const handlePassoword = (event: any) => {
    hideErrorMessage();
    setPassword(event);
  };

  React.useEffect(() => {
    async function verifyToken(token: any) {
      const response = await verify(token);
      if (!response) {
        showErrorMessage("Token is not valid!");
      } else setUser(response.data);
    }
    verifyToken(accessToken);
  }, [accessToken]);

  const handleSubmit = async (password: string) => {
    if (passwordStrengthStatus !== PasswordStrengthStatuses.STRONG) {
      showErrorMessage("You does not set a strong password!");
      return;
    }

    await joinCompany({ password, token: accessToken });

    navigate("/");
  };

  const showErrorMessage = (message: string) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const hideErrorMessage = () => {
    setErrorMessage("");
    setShowError(false);
  };

  const handlePasswordStrengthCheck = async (
    status: PasswordStrengthStatuses
  ) => {
    setPasswordStrengthStatus(status);
  };

  return (
    <>
      <IndexLayout>
        {user ? (
          <SetPasswordWrapper>
            <h2>Hello, {user.firstName}</h2>
            <WelcomeWrapper>
              You have been invited by {user.organization} to the {user.team} as a {user.position}. Please set your password to activate your account and then log in.              
            </WelcomeWrapper>
            <InputTextComponent
              onChildStateChange={handlePassoword}
              placeholder="Set your password"
              type="password"
              width={264}
            />
            {password ? (
              <PasswordStrengthWrapper>
                <PasswordStrengthComponent
                  password={password}
                  onScoringFinished={handlePasswordStrengthCheck}
                />{" "}
              </PasswordStrengthWrapper>
            ) : null}
            {showError ? (
              <InfoMessageComponent
                text={errorMessage}
                type={MessageTypes.Error}
              />
            ) : null}
            <ButtonWrapper>
              <LoginButtonComponent
                onClick={() => handleSubmit(password)}
                color="#1760a8"
                textColor="white"
                text="Join the company"
              />
            </ButtonWrapper>
          </SetPasswordWrapper>
        ) : null}
      </IndexLayout>
    </>
  );
};

export default VerifyPage;
