import * as React from 'react'
import IconTextComponent from '../../components/IconText/IconText.component'
import LogoComponent from '../../components/logo/Logo.component'
import ProfileIconComponent from '../../components/ProfileIcon/ProfileIcon.component'
import './styles/dashboard.style.css';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../../services/auth.service'

interface Props {
    children: React.ReactNode
}
const DashboardLayout: React.FunctionComponent<Props> = (props:Props) => {
    const navigate = useNavigate();    
    const user = getCurrentUser();
    
    return <>
        <div className="wrapper">
            <div className="settings-wrapper"></div>
            <div className="sidebar-wrapper">
                <div className="sidebar-heading">
                    <LogoComponent company={user.organization.name} />
                </div>
                <div className="list-group">
                    <ul className="list-items">                        
                        <li><IconTextComponent onClick={() => navigate('/boarding')} symbol="M" text="My Onboarding" color="#2a963c" /></li>                        
                        <li><IconTextComponent onClick={() => navigate('/team')} symbol="T" text="My Team" color="#5d4dab" /></li>                        
                    </ul>
                </div>                
                <div className="divider"></div>
                {/*<div className="list-docs">*/}
                {/*    <ul className="ul-docs">*/}
                {/*        <li>Docs <span className="outspan"><img alt="" src={SquareSolid} width="12" /></span></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                {/*<div className="divider"></div>*/}
            </div>
            <div className="page-content-wrapper">
                <div className="header">
                <ProfileIconComponent symbol={`${user.firstName[0]}${user.lastName[0]}`} />      
                </div>
                <div className="children-pad">
                    {props.children}
                </div>
            </div>
        </div>
    </>
}

export default DashboardLayout;