import { useNavigate } from "react-router-dom";
import IndexLayout from "../../../layouts/index/IndexLayout";
import InputTextComponent from "../../../components/input/InputText.component";
import LoginButtonComponent from "../../../components/Buttons/LoginButton.component";
import { login } from '../../../services/auth.service';
import {  useState } from 'react';
import "./styles/login.css";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 22px;
  color: #1a1c1b;
`;

const ErrorWrapper = styled.div`
  width: 400px;
  height: 20px;
  margin-top: 20px;
  padding: 5px;
  background: #ff8f9a;
  border: 1px solid #a8323e;
`;

function LoginPage() {  
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const handleSubmitfunc = async () => {
    try {
    const user = await login(email, password);
    
    if (user.email) {
      navigate('/boarding');
    } 
  } catch(e) {    
    setError(true);
  }
  }

  const handleChildStateChange = (childState: string) => {
    setEmail(childState);
  };

  const handleChildStateChangePassword  = (childState: string) => {
    setPassword(childState);
  };

  return (
    <IndexLayout>
      <div className="Texthead">
        <Title>Welcome to Rocket Desert</Title>
        <span>It's Employee Dashboard</span>
      </div>
      <div className="responsive-head">
        <h3>Welcome to Rocket Desert</h3>
        <span>It's employee dashboard</span>
      </div>
      <div className="form">        
        <InputTextComponent onChildStateChange={handleChildStateChange}  type="text" placeholder="Email" />        
        <InputTextComponent onChildStateChange={handleChildStateChangePassword} type="password" placeholder="Password" />        
        <div>
            {error ? <ErrorWrapper>Please use correct credentials!</ErrorWrapper> : null}
        </div>

        <div className="loginButton">
          <LoginButtonComponent
            text="Sign In"
            color="gray"
            textColor="#fff"
            onClick={handleSubmitfunc}
          />
        </div>
        <div className="forgotPassword">
          <a href="/password/reset"><span>Forgot password?</span></a>
        </div>                       
      </div>
    </IndexLayout>
  );
}

export default LoginPage;
