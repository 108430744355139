import { apiClient } from "../infrastructure/api/apiClient";

export const getUserOnboarding = () => {
  return apiClient.get("/boarding/my-onboarding")
  .then((response)=> {    
    return response.data;
  });;
};

export const getTaskDetails = (id:string) => {
  return apiClient.get(`/task/details/${id}`)
  .then((response)=> {    
    return response.data;
  });
};

export const getTaskAssessments = (taskId:string) => {
  return apiClient.get(`/action/get-employee-assessments/${taskId}`)
  .then((response)=> {    
    return response.data;
  });
};


export const checkAssessmentScore = (taskId: string, payload: any) => {
  return apiClient.post(`/action/employee-assessment-score/${taskId}`, payload)
  .then((response)=> {    
    return response.data;
  });
};


